import * as React from "react";
import cx from "classcat";
import { NavLink, useLocation } from "react-router-dom";
import variantUnderlineStyles from "./NavUnderline.module.css";
import variantTabsStyles from "./NavTabs.module.css";
import variantVerticalStyles from "./NavVertical.module.css";

type IVariant = "primary" | "secondary" | "vertical";

const NavContext = React.createContext<{
  variant: IVariant;
}>(
  // @ts-ignore
  undefined
);

export function Nav(props: { children: React.ReactNode; variant?: IVariant }) {
  const { variant = "primary" } = props;
  const styles = variantToStyles[variant];
  return (
    <NavContext.Provider value={{ variant }}>
      <nav className={styles.navList}>{props.children}</nav>
    </NavContext.Provider>
  );
}

export function NavItem(
  props: React.ComponentProps<typeof NavLink> & {
    isActive?: boolean;
    disabled?: boolean;
  }
) {
  const location = useLocation();
  const {
    children,
    end = true,
    disabled = false,
    isActive = location.pathname.startsWith(props.to as string),
    ...rest
  } = props;
  const { variant } = React.useContext(NavContext);
  const styles = variantToStyles[variant];
  return (
    <NavLink
      // className={({ isActive }) =>
      className={() =>
        cx([
          styles.link,
          isActive && styles.linkActive,
          disabled && styles.linkDisabled,
        ])
      }
      end={end}
      {...rest}
    >
      <div className={styles.navTabLayout}>{children}</div>
    </NavLink>
  );
}

const variantToStyles: { [k: string]: any } = {
  primary: variantUnderlineStyles,
  secondary: variantTabsStyles,
  vertical: variantVerticalStyles,
};
