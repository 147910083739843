import Logo from "../assets/logo.png";
import { PressableLink } from "./lib/PressableLink";
import { View } from "./lib/View";
import { HeaderMenu } from "./HeaderMenu";
import { links } from "../shared/links";
import styles from "./Header.module.css";
import { appName } from "../config";

const headerLinks = links.slice(0, 2);

export function Header() {
  const logoLink = (
    <PressableLink to="/">
      <img src={Logo} alt={appName} className={styles.logo} />
    </PressableLink>
  );
  return (
    <header className={styles.header}>
      <View style={styles.headerLayout}>
        {logoLink}
        <View style={styles.headerLinks}>
          {headerLinks.map((x, i) => (
            <PressableLink key={i} to={x.to}>
              <View style={styles.headerLink}>
                <x.icon />
                <View>{x.text}</View>
              </View>
            </PressableLink>
          ))}
        </View>
        <View style={styles.headerEndLayout}>
          <HeaderMenu />
        </View>
      </View>
    </header>
  );
}
