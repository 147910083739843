import * as React from "react";
import { useQuery } from "react-query";
import { Menu, MenuItem } from "./lib/MenuButton";
import { View } from "./lib/View";
import { Text } from "./lib/Text";
// import { otherLinks } from "../shared/links";
import { useAuth } from "../shared/useAuth";
import { useSDK } from "../shared/useSDK";
import { useUser } from "../shared/useUser";
import { ActivityIndicator } from "./lib/ActivityIndicator";
import { Avatar } from "./lib/Avatar";
import { HeaderMenuButton } from "./HeaderMenuButton";
import { HeaderMenuPopover } from "./HeaderMenuPopover";
import ColorHash from "color-hash-ts";
import { getAvatarColor } from "../shared/getAvatarColor";
import styles from "./HeaderMenu.module.css";

export { Menu, MenuList, MenuItem, MenuLink } from "./lib/MenuButton";

const colorHash = new ColorHash();

export function HeaderMenu() {
  const { signOut } = useAuth();
  const { user } = useUser();
  const avatarBackgroundColor = colorHash.hex(user.uuid);
  const avatarColor = getAvatarColor(avatarBackgroundColor);
  return (
    <Menu>
      <HeaderMenuButton noArrow>
        <Avatar
          src={
            // @ts-ignore
            user.avatarImageUrl
          }
          fallback={
            // @ts-ignore
            user.firstName ?? ""
          }
          fallbackBackgroundColor={avatarBackgroundColor}
          fallbackColor={avatarColor}
          size="xs"
        />
      </HeaderMenuButton>
      <HeaderMenuPopover>
        <HeaderMenuUserSection />
        {/* <HeaderMenuOrgSection /> */}
        {/* <View style={styles.section}> */}
        {/* <MenuItems>
            {otherLinks.map((x, i) => {
              if (x.isExternal) {
                return (
                  <MenuLink
                    key={i}
                    href={x.to}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {x.text}
                  </MenuLink>
                );
              }
              let to = x.to;
              return (
                <MenuLink
                  key={i}
                  as={RLink as any}
                  // @ts-ignore
                  to={to}
                >
                  {x.text}
                </MenuLink>
              );
            })}
          </MenuItems> */}
        {/* </View> */}
        <View style={styles.signOutSection}>
          <MenuItem onSelect={signOut}>Sign Out</MenuItem>
        </View>
      </HeaderMenuPopover>
    </Menu>
  );
}

function HeaderMenuUserSection() {
  const { sdk } = useSDK();
  const meResult = useQuery("me", () => sdk.getMe());
  const fallbackText = "User";
  function _render() {
    if (meResult.status === "loading") {
      return <ActivityIndicator />;
    }
    if (meResult.status === "error" || meResult.data === undefined) {
      return <Text style={styles.userName}>{fallbackText}</Text>;
    }
    const me = meResult.data.data;
    return (
      <Text style={styles.userName}>
        {[me.firstName, me.lastName].join(" ").trim() || fallbackText}
      </Text>
    );
  }
  return <View style={styles.userSection}>{_render()}</View>;
}

export function HeaderMenuItemLayout(props: { children: React.ReactNode }) {
  return <View style={styles.menuItemLayout}>{props.children}</View>;
}
