/* eslint-disable */

import * as qs from "qs";
class CustomError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}
export class SDKResponseError extends CustomError {
    constructor(error, status) {
        super("Bad response");
        this.error = error;
        this.status = status;
    }
}
export class SDK {
    constructor(opts) {
        this.deleteAp = function (params, query, opts) {
            let path = "/v1/access-points/:ap_uuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteApGroup = function (params, query, opts) {
            let path = "/v1/ap-groups/:ap_group_uuid";
            return this._delete(path, params, query, opts);
        };
        this.disassociateApFromApGroup = function (params, query, opts) {
            let path = "/v1/ap-groups/:ap_group_uuid/access-points/:ap_uuid/disassociate";
            return this._delete(path, params, query, opts);
        };
        this.deleteAttributeType = function (params, query, opts) {
            let path = "/v1/attribute-types/:resource_type/:attribute_type_uuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteAttribute = function (params, query, opts) {
            let path = "/v1/attributes/:resource_type/:attribute_uuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteDevice = function (params, query, opts) {
            let path = "/v1/devices/:device_uuid";
            return this._delete(path, params, query, opts);
        };
        this.removeApiKey = function (params, query, opts) {
            let path = "/v1/identities/:identity_uuid/api-keys/:api_key_uuid";
            return this._delete(path, params, query, opts);
        };
        this.removeIdentityFromOrg = function (params, query, opts) {
            let path = "/v1/organizations/:uuid/identities/:identity_username";
            return this._delete(path, params, query, opts);
        };
        this.removeIdentityFromRole = function (params, query, opts) {
            let path = "/v1/roles/:uuid/identities/:identity_uuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteService = function (params, query, opts) {
            let path = "/v1/services/:service_uuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteTrustDomain = function (params, query, opts) {
            let path = "/v1/trust-domains/:trust_domain_uuid";
            return this._delete(path, params, query, opts);
        };
        this.deleteWebhook = function (params, query, opts) {
            let path = "/v1/webhooks/:webhook_uuid";
            return this._delete(path, params, query, opts);
        };
        this.__setBaseUrl(opts.baseUrl);
        this._queryStringOpts = opts.queryStringOpts;
        if (opts?.headers) {
            this._headers = opts.headers ?? {};
        }
    }
    __setBaseUrl(baseUrl) {
        this._baseUrl = baseUrl;
    }
    __setHeaders(fn) {
        this._headers = fn(this._headers);
    }
    __clearCache() { }
    getApList(params, query, opts) {
        let path = "/v1/access-points";
        return this._get(path, params, query, opts);
    }
    createAp(params, data, query, opts) {
        let path = "/v1/access-points";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getApEventByEventUuid(params, query, opts) {
        let path = "/v1/access-points/events/:event_uuid";
        return this._get(path, params, query, opts);
    }
    refreshApToken(params, data, query, opts) {
        let path = "/v1/access-points/refresh-token";
        return this._post(path, params, data, query, opts, "application/json");
    }
    registerAp(params, data, query, opts) {
        let path = "/v1/access-points/register";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getApToken(params, data, query, opts) {
        let path = "/v1/access-points/token";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getApByUuid(params, query, opts) {
        let path = "/v1/access-points/:ap_uuid";
        return this._get(path, params, query, opts);
    }
    updateAp(params, data, query, opts) {
        let path = "/v1/access-points/:ap_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getApApGroupList(params, query, opts) {
        let path = "/v1/access-points/:ap_uuid/ap-groups";
        return this._get(path, params, query, opts);
    }
    getApDeviceList(params, query, opts) {
        let path = "/v1/access-points/:ap_uuid/devices";
        return this._get(path, params, query, opts);
    }
    getApEventList(params, query, opts) {
        let path = "/v1/access-points/:ap_uuid/events";
        return this._get(path, params, query, opts);
    }
    updateApEventByEventUuid(params, data, query, opts) {
        let path = "/v1/access-points/:ap_uuid/events/:event_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getApGroupList(params, query, opts) {
        let path = "/v1/ap-groups";
        return this._get(path, params, query, opts);
    }
    createApGroup(params, data, query, opts) {
        let path = "/v1/ap-groups";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getApGroup(params, query, opts) {
        let path = "/v1/ap-groups/:ap_group_uuid";
        return this._get(path, params, query, opts);
    }
    updateApGroup(params, data, query, opts) {
        let path = "/v1/ap-groups/:ap_group_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getApsInApGroup(params, query, opts) {
        let path = "/v1/ap-groups/:ap_group_uuid/access-points";
        return this._get(path, params, query, opts);
    }
    associateApWithApGroup(params, data, query, opts) {
        let path = "/v1/ap-groups/:ap_group_uuid/access-points/:ap_uuid/associate";
        return this._patch(path, params, data, query, opts, undefined);
    }
    getServicesInApGroup(params, query, opts) {
        let path = "/v1/ap-groups/:ap_group_uuid/services";
        return this._get(path, params, query, opts);
    }
    getAttributeTypeList(params, query, opts) {
        let path = "/v1/attribute-types/:resource_type";
        return this._get(path, params, query, opts);
    }
    createAttributeType(params, data, query, opts) {
        let path = "/v1/attribute-types/:resource_type";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getAttributeType(params, query, opts) {
        let path = "/v1/attribute-types/:resource_type/:attribute_type_uuid";
        return this._get(path, params, query, opts);
    }
    updateAttributeType(params, data, query, opts) {
        let path = "/v1/attribute-types/:resource_type/:attribute_type_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getAttributeList(params, query, opts) {
        let path = "/v1/attributes/:resource_type";
        return this._get(path, params, query, opts);
    }
    getAttribute(params, query, opts) {
        let path = "/v1/attributes/:resource_type/:attribute_uuid";
        return this._get(path, params, query, opts);
    }
    updateAttribute(params, data, query, opts) {
        let path = "/v1/attributes/:resource_type/:attribute_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    createAttribute(params, data, query, opts) {
        let path = "/v1/attributes/:resource_type/:resource_uuid";
        return this._post(path, params, data, query, opts, "application/json");
    }
    completeResetPassword(params, data, query, opts) {
        let path = "/v1/complete-reset-password";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getDeviceList(params, query, opts) {
        let path = "/v1/devices";
        return this._get(path, params, query, opts);
    }
    createDevice(params, data, query, opts) {
        let path = "/v1/devices";
        return this._post(path, params, data, query, opts, "application/json");
    }
    refreshDeviceToken(params, data, query, opts) {
        let path = "/v1/devices/refresh-token";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getDeviceToken(params, data, query, opts) {
        let path = "/v1/devices/token";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getDeviceByUuid(params, query, opts) {
        let path = "/v1/devices/:device_uuid";
        return this._get(path, params, query, opts);
    }
    updateDevice(params, data, query, opts) {
        let path = "/v1/devices/:device_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    resetDevicePassphrase(params, query, opts) {
        let path = "/v1/devices/:device_uuid/reset-passphrase";
        return this._get(path, params, query, opts);
    }
    runHealthCheck(params, query, opts) {
        let path = "/v1/health-check";
        return this._get(path, params, query, opts);
    }
    createIdentity(params, data, query, opts) {
        let path = "/v1/identities/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    updateIdentity(params, data, query, opts) {
        let path = "/v1/identities/:identity_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    addApiKey(params, data, query, opts) {
        let path = "/v1/identities/:identity_uuid/api-keys";
        return this._post(path, params, data, query, opts, undefined);
    }
    updateUserAdminStatus(params, data, query, opts) {
        let path = "/v1/identities/:user_uuid/admin-status";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    loginUser(params, data, query, opts) {
        let path = "/v1/login";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getLogEvents(params, query, opts) {
        let path = "/v1/logs";
        return this._get(path, params, query, opts);
    }
    getMe(params, query, opts) {
        let path = "/v1/me";
        return this._get(path, params, query, opts);
    }
    mqttApLogin(params, data, query, opts) {
        let path = "/v1/mqtt-broker/login";
        return this._post(path, params, data, query, opts, "application/json");
    }
    mqttVerifyApAccess(params, data, query, opts) {
        let path = "/v1/mqtt-broker/verify/ap-access/:ap_uuid";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getOrganizationList(params, query, opts) {
        let path = "/v1/organizations/";
        return this._get(path, params, query, opts);
    }
    createOrganization(params, data, query, opts) {
        let path = "/v1/organizations/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getOneOrganization(params, query, opts) {
        let path = "/v1/organizations/:uuid";
        return this._get(path, params, query, opts);
    }
    updateOneOrganization(params, data, query, opts) {
        let path = "/v1/organizations/:uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getIdentitiesForOrg(params, query, opts) {
        let path = "/v1/organizations/:uuid/identities";
        return this._get(path, params, query, opts);
    }
    addIdentityToOrg(params, data, query, opts) {
        let path = "/v1/organizations/:uuid/identities/:identity_username";
        return this._patch(path, params, data, query, opts, undefined);
    }
    getPermissionList(params, query, opts) {
        let path = "/v1/permissions/";
        return this._get(path, params, query, opts);
    }
    createPermission(params, data, query, opts) {
        let path = "/v1/permissions/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getPermission(params, query, opts) {
        let path = "/v1/permissions/:permission_uuid";
        return this._get(path, params, query, opts);
    }
    updatePermission(params, data, query, opts) {
        let path = "/v1/permissions/:permission_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    claimedPskLookup(params, data, query, opts) {
        let path = "/v1/psks/claimed-psk-lookup";
        return this._post(path, params, data, query, opts, "application/json");
    }
    pskLookup(params, data, query, opts) {
        let path = "/v1/psks/psk-lookup";
        return this._post(path, params, data, query, opts, "application/json");
    }
    initiatePasswordReset(params, data, query, opts) {
        let path = "/v1/reset-password";
        return this._post(path, params, data, query, opts, undefined);
    }
    getRoleList(params, query, opts) {
        let path = "/v1/roles/";
        return this._get(path, params, query, opts);
    }
    createRole(params, data, query, opts) {
        let path = "/v1/roles/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getRole(params, query, opts) {
        let path = "/v1/roles/:uuid";
        return this._get(path, params, query, opts);
    }
    updateRole(params, data, query, opts) {
        let path = "/v1/roles/:uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    addIdentityToRole(params, data, query, opts) {
        let path = "/v1/roles/:uuid/identities/:identity_uuid";
        return this._patch(path, params, data, query, opts, undefined);
    }
    getServiceList(params, query, opts) {
        let path = "/v1/services";
        return this._get(path, params, query, opts);
    }
    createService(params, data, query, opts) {
        let path = "/v1/services";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getService(params, query, opts) {
        let path = "/v1/services/:service_uuid";
        return this._get(path, params, query, opts);
    }
    updateService(params, data, query, opts) {
        let path = "/v1/services/:service_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    sseStream(params, query, opts) {
        let path = "/v1/sse/event-stream";
        return this._get(path, params, query, opts);
    }
    getSsidList(params, query, opts) {
        let path = "/v1/ssids";
        return this._get(path, params, query, opts);
    }
    updateIdentitySuperAdmin(params, data, query, opts) {
        let path = "/v1/super/identities/:identity_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getSystemResourceListSuperAdmin(params, query, opts) {
        let path = "/v1/super/system-resources/";
        return this._get(path, params, query, opts);
    }
    createSystemResourceSuperAdmin(params, data, query, opts) {
        let path = "/v1/super/system-resources/";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getSystemResourceSuperAdmin(params, query, opts) {
        let path = "/v1/super/system-resources/:system_resource_uuid";
        return this._get(path, params, query, opts);
    }
    updateSystemResourceSuperAdmin(params, data, query, opts) {
        let path = "/v1/super/system-resources/:system_resource_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    getSystemResourceList(params, query, opts) {
        let path = "/v1/system-resources/";
        return this._get(path, params, query, opts);
    }
    getSystemResource(params, query, opts) {
        let path = "/v1/system-resources/:system_resource_uuid";
        return this._get(path, params, query, opts);
    }
    refreshAs(params, query, opts) {
        let path = "/v1/system/verify";
        return this._get(path, params, query, opts);
    }
    getTrustDomainList(params, query, opts) {
        let path = "/v1/trust-domains";
        return this._get(path, params, query, opts);
    }
    createTrustDomain(params, data, query, opts) {
        let path = "/v1/trust-domains";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getTrustDomain(params, query, opts) {
        let path = "/v1/trust-domains/:trust_domain_uuid";
        return this._get(path, params, query, opts);
    }
    updateTrustDomain(params, data, query, opts) {
        let path = "/v1/trust-domains/:trust_domain_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    resetTrustDomainPassphrase(params, query, opts) {
        let path = "/v1/trust-domains/:trust_domain_uuid/reset-passphrase";
        return this._get(path, params, query, opts);
    }
    getWebhookList(params, query, opts) {
        let path = "/v1/webhooks";
        return this._get(path, params, query, opts);
    }
    createWebhook(params, data, query, opts) {
        let path = "/v1/webhooks";
        return this._post(path, params, data, query, opts, "application/json");
    }
    getWebhookByUuid(params, query, opts) {
        let path = "/v1/webhooks/:webhook_uuid";
        return this._get(path, params, query, opts);
    }
    updateWebhook(params, data, query, opts) {
        let path = "/v1/webhooks/:webhook_uuid";
        return this._patch(path, params, data, query, opts, "application/json");
    }
    async _get(path, params, query, opts) {
        const { hydratedPath } = findParamArguments(path, params);
        const url = `${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`;
        const cacheKey = `GET${url}`;
        let headers = { "Content-Type": "application/json" };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(url, { headers });
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        const out = { data: json, status: res.status };
        return out;
    }
    async _post(path, params, data, query, opts, requestBodyContentType) {
        const { hydratedPath } = findParamArguments(path, params);
        let headers = !requestBodyContentType
            ? {}
            : { "Content-Type": requestBodyContentType };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`, {
            method: "POST",
            headers,
            body: requestBodyContentType === "application/json"
                ? JSON.stringify(data)
                : data
        });
        if (res.status === 204) {
            return { data: undefined, status: res.status };
        }
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        return { data: json, status: res.status };
    }
    async _patch(path, params, data, query, opts, requestBodyContentType) {
        const { hydratedPath } = findParamArguments(path, params);
        let headers = !requestBodyContentType
            ? {}
            : { "Content-Type": requestBodyContentType };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`, {
            method: "PATCH",
            headers,
            body: requestBodyContentType === "application/json"
                ? JSON.stringify(data)
                : data
        });
        if (res.status === 204) {
            return { data: undefined, status: res.status };
        }
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        return { data: json, status: res.status };
    }
    async _put(path, params, data, query, opts, requestBodyContentType) {
        const { hydratedPath } = findParamArguments(path, params);
        let headers = !requestBodyContentType
            ? {}
            : { "Content-Type": requestBodyContentType };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`, {
            method: "PUT",
            headers,
            body: requestBodyContentType === "application/json"
                ? JSON.stringify(data)
                : data
        });
        if (res.status === 204) {
            return { data: undefined, status: res.status };
        }
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        return { data: json, status: res.status };
    }
    async _delete(path, params, query, opts) {
        const { hydratedPath } = findParamArguments(path, params);
        let headers = { "Content-Type": "application/json" };
        if (this._headers) {
            headers = { ...headers, ...this._headers };
        }
        if (opts?.headers) {
            headers = { ...headers, ...opts.headers };
        }
        let res = await fetch(`${this._baseUrl}${hydratedPath}${generateQueryString(query, this._queryStringOpts)}`, { method: "DELETE", headers });
        if (res.status === 204) {
            return { data: undefined, status: res.status };
        }
        const json = await res.json();
        if (!res.ok) {
            throw new SDKResponseError(json, res.status);
        }
        return { data: json, status: res.status };
    }
}
function findParamArguments(path, obj) {
    let parts = path.split(/\//);
    let params = parts.filter(p => p.length > 0 && p[0] === ":");
    let args = params.map(p => p.substring(1, p.length));
    let out = {};
    let hydratedPath = path;
    if (obj) {
        args.forEach(e => {
            out[e] = obj[e];
            hydratedPath = hydratedPath.replace(`:${e}`, obj[e]);
        });
    }
    return { args, out, hydratedPath };
}
function generateQueryString(obj, _opts) {
    let opts = { addQueryPrefix: true, encode: true };
    if (_opts) {
        opts = { ...opts, ..._opts };
    }
    return qs.stringify(obj, opts);
}
