import * as React from "react";
import cx from "classcat";

type ClassName = string | undefined;
type Overrides = {
  style?: ClassName | Array<ClassName | boolean>;
};

export const Text = React.forwardRef<
  HTMLDivElement,
  Omit<React.ComponentProps<"span">, "className" | "style"> & Overrides
>((props, ref) => {
  const { children, style, ...rest } = props;
  const className = Array.isArray(style) ? cx(style) : style;
  return (
    <span ref={ref} className={className} {...rest}>
      {children}
    </span>
  );
});
