import * as React from "react";
import { SDK } from "../sdk";
import type { SDK as ISDK } from "../sdk";

export function useSDK() {
  return React.useContext(SDKContext);
}

export function SDKProvider(props: T & { children: React.ReactNode }) {
  const { children, sdk } = props;
  return <SDKContext.Provider value={{ sdk }}>{children}</SDKContext.Provider>;
}

type T = { sdk: ISDK };

const SDKContext = React.createContext<T>({
  sdk: new SDK({ baseUrl: "" }),
});
