import * as React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import type { Location } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useQueryClient,
} from "react-query";
import { AuthProvider } from "./shared/useAuth";
import { UserProvider } from "./shared/useUser";
import { SchemasProvider, getSchemas } from "./shared/useSchema";
import { SDK } from "./sdk";
import { useSDK, SDKProvider } from "./shared/useSDK";
import * as auth from "./shared/auth";
import { FullScreenLoading } from "./components/FullScreenLoading";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Layout } from "./components/Layout";
import { apiBaseUrl } from "./config";
import { ErrorMessage } from "./components/ErrorMessage";
import { redirectLocationKey } from "./shared/redirectLocationKey";
import { StandardRouteElement } from "./components/StandardRouteElement";
import { safeJSONParse } from "./lib/safeJSONParse";
// @ts-expect-error
import { EventSourcePolyfill } from "event-source-polyfill";

const NotFound = React.lazy(() => import("./screens/404/404"));
const Login = React.lazy(() => import("./screens/login/index"));
const RequestPasswordReset = React.lazy(
  () => import("./screens/request-password-reset/index")
);
const PasswordReset = React.lazy(
  () => import("./screens/password-reset/index")
);
const AccessPointGroups = React.lazy(
  () => import("./screens/ap-groups/routes")
);
const AccessPoints = React.lazy(() => import("./screens/access-points/routes"));
const Services = React.lazy(() => import("./screens/services/routes"));
const Devices = React.lazy(() => import("./screens/devices/routes"));
const TrustDomains = React.lazy(() => import("./screens/trust-domains/routes"));

const queryClient = new QueryClient();

export default function App() {
  const token = auth.getToken();
  const sdk = new SDK({ baseUrl: apiBaseUrl });
  if (token != null) {
    sdk.__setHeaders((x: any) => ({ ...x, "x-api-token": token }));
  }
  const signIn = (token: string, cb?: () => void) => {
    auth.setToken(token);
    sdk.__setHeaders((x: any) => ({ ...x, "x-api-token": token }));
    cb?.();
  };
  const signOut = () => {
    window.localStorage.clear();
    queryClient.clear();
    window.location.href = "/login";
  };

  return (
    <ErrorBoundary>
      <ToastContainer limit={1} />
      <AuthProvider
        token={auth.getToken() as string}
        signIn={signIn}
        signOut={signOut}
      >
        <SDKProvider sdk={sdk}>
          <QueryClientProvider client={queryClient}>
            <WithSse token={token}>
              <BrowserRouter>
                <Routes>
                  <Route element={<RedirectIfAuthenticated />}>
                    <Route
                      path="login"
                      element={<StandardRouteElement element={<Login />} />}
                    />
                    <Route
                      // See config.py
                      path="request-reset-password"
                      element={
                        <StandardRouteElement
                          element={<RequestPasswordReset />}
                        />
                      }
                    />
                    <Route
                      // See config.py
                      path="complete-reset-password"
                      element={
                        <StandardRouteElement element={<PasswordReset />} />
                      }
                    />
                  </Route>
                  {/* private routes */}
                  <Route
                    element={
                      <RequireAuth redirectTo={{ pathname: "/login" }}>
                        <FetchDeps>
                          <Layout />
                        </FetchDeps>
                      </RequireAuth>
                    }
                  >
                    <Route
                      index
                      element={
                        <StandardRouteElement element={<AccessPointGroups />} />
                      }
                    />
                    {[
                      { path: "ap-groups/*", element: <AccessPointGroups /> },
                      { path: "access-points/*", element: <AccessPoints /> },
                      { path: "services/*", element: <Services /> },
                      { path: "devices/*", element: <Devices /> },
                      { path: "trust-domains/*", element: <TrustDomains /> },
                    ].map((x, i) => (
                      <Route
                        key={i}
                        path={x.path}
                        element={<StandardRouteElement element={x.element} />}
                      />
                    ))}
                  </Route>
                  {/* 404 */}
                  <Route
                    path="*"
                    element={<StandardRouteElement element={<NotFound />} />}
                  />
                </Routes>
              </BrowserRouter>
            </WithSse>
          </QueryClientProvider>
        </SDKProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

function RequireAuth(props: {
  children: JSX.Element;
  redirectTo: Partial<Location>;
}) {
  const { children, redirectTo } = props;
  const location = useLocation();
  const isAuthenticated = auth.isAuthenticated();
  const token = auth.getToken();
  return isAuthenticated && token != null ? (
    children
  ) : (
    <Navigate to={redirectTo} state={{ [redirectLocationKey]: location }} />
  );
}

function RedirectIfAuthenticated() {
  const location = useLocation();
  const isAuthenticated = auth.isAuthenticated();
  const token = auth.getToken();
  const redirectLocation =
    (location.state as any)?.[redirectLocationKey]?.pathname || "/";
  return isAuthenticated && token != null ? (
    <Navigate to={redirectLocation} />
  ) : (
    <Outlet />
  );
}

function WithSse(props: { children: React.ReactNode; token: string | null }) {
  const { token, children } = props;
  const queryClient = useQueryClient();

  React.useEffect(() => {
    const source = new EventSourcePolyfill(
      `${apiBaseUrl}/v1/sse/event-stream`,
      { headers: { "x-api-token": token } }
    );

    // source.addEventListener("open", () => {
    //   console.log("SSE opened!");
    // });

    source.addEventListener("message", (e: any) => {
      const data = safeJSONParse(e.data);
      if (data == null) {
        return;
      }
      if (
        data.event === "AP_PARAMETER_UPDATE" &&
        data.metadata?.apUuid != null
      ) {
        queryClient.refetchQueries(["getApByUuid", data.metadata.apUuid], {
          exact: true,
          active: true,
        });
        queryClient.refetchQueries(["getApList"], {
          exact: false,
          active: true,
        });
      }
      if (
        (data.event === "AP_INCLUDED_IN_AP_GROUP" ||
          data.event === "AP_EXCLUDED_FROM_AP_GROUP") &&
        data.metadata?.apGroupUuid != null
      ) {
        queryClient.refetchQueries(
          ["getAccessPointsInApGroup", data.metadata.apGroupUuid],
          { exact: false, active: true }
        );
      }
      if (
        data.event === "AP_GROUP_UPDATE" &&
        data.metadata?.apGroupUuid != null
      ) {
        queryClient.refetchQueries(["getApGroup", data.metadata.apGroupUuid], {
          exact: true,
          active: true,
        });
        queryClient.refetchQueries(["getApGroupList"], {
          exact: false,
          active: true,
        });
      }
      if (
        data.event === "AP_PROVISON_SERVICE" &&
        data.metadata?.serviceUuid != null
      ) {
        queryClient.refetchQueries(["getServiceList"], {
          exact: false,
          active: true,
        });
        if (data?.metadata?.apGroupUuid != null) {
          queryClient.refetchQueries(
            ["getServicesInApGroup", data.metadata.apGroupUuid],
            { exact: false, active: true }
          );
        }
      }
      if (
        data.event === "AP_UPDATE_SERVICE" &&
        data.metadata?.serviceUuid != null
      ) {
        queryClient.refetchQueries(["getService", data.metadata.serviceUuid], {
          exact: true,
          active: true,
        });
        queryClient.refetchQueries(["getServiceList"], {
          exact: false,
          active: true,
        });
        if (data?.metadata?.apGroupUuid != null) {
          queryClient.refetchQueries(
            ["getServicesInApGroup", data.metadata.apGroupUuid],
            { exact: false, active: true }
          );
        }
      }
      if (
        data.event === "AP_REMOVE_SERVICE" &&
        data.metadata?.serviceUuid != null
      ) {
        queryClient.refetchQueries(["getServiceList"], {
          exact: false,
          active: true,
        });
        if (data?.metadata?.apGroupUuid != null) {
          queryClient.refetchQueries(
            ["getServicesInApGroup", data.metadata.apGroupUuid],
            { exact: false, active: true }
          );
        }
      }
      if (
        data.event === "AP_PROVISION_DEVICE" &&
        data.metadata?.deviceUuid != null
      ) {
        queryClient.refetchQueries(["getDeviceList"], {
          exact: false,
          active: true,
        });
        queryClient.refetchQueries(["getApDeviceList"], {
          exact: false,
          active: true,
        });
        if (data?.metadata?.serviceUuid != null) {
          queryClient.refetchQueries(
            ["getServiceDeviceList", data.metadata.serviceUuid],
            { exact: false, active: true }
          );
        }
      }
      if (
        data.event === "AP_UPDATE_DEVICE" &&
        data.metadata?.deviceUuid != null
      ) {
        queryClient.refetchQueries(
          ["getDeviceByUuid", data.metadata.deviceUuid],
          { exact: true, active: true }
        );
        queryClient.refetchQueries(["getDeviceList"], {
          exact: false,
          active: true,
        });
        queryClient.refetchQueries(["getApDeviceList"], {
          exact: false,
          active: true,
        });
        if (data?.metadata?.serviceUuid != null) {
          queryClient.refetchQueries(
            ["getServiceDeviceList", data.metadata.serviceUuid],
            { exact: false, active: true }
          );
        }
      }
      if (
        data.event === "AP_REMOVE_DEVICE" &&
        data.metadata?.deviceUuid != null
      ) {
        queryClient.refetchQueries(["getDeviceList"], {
          exact: false,
          active: true,
        });
        queryClient.refetchQueries(["getApDeviceList"], {
          exact: false,
          active: true,
        });
        if (data?.metadata?.serviceUuid != null) {
          queryClient.refetchQueries(
            ["getServiceDeviceList", data.metadata.serviceUuid],
            { exact: false, active: true }
          );
        }
      }
    });

    source.addEventListener("error", (e: any) => {
      console.error(e);
    });

    return () => {
      source.close();
    };
  }, []);

  return <>{children}</>;
}

function FetchDeps(props: { children: React.ReactNode }) {
  const { sdk } = useSDK();
  const resultMe = useQuery("me", () => sdk.getMe());
  const resultSchemas = useQuery(["getOpenApiDoc"], () =>
    getOpenApiDoc().then((result) => getSchemas(result.data))
  );
  if (resultMe.status === "loading" || resultSchemas.status === "loading") {
    return <FullScreenLoading />;
  }
  if (
    resultMe.status === "error" ||
    resultMe.data === undefined ||
    resultSchemas.status === "error" ||
    resultSchemas.data === undefined
  ) {
    return <ErrorMessage error={resultMe.error} />;
  }
  const user = resultMe.data.data;
  const schemas = resultSchemas.data;
  return (
    <SchemasProvider schemas={schemas}>
      <UserProvider user={user}>{props.children}</UserProvider>
    </SchemasProvider>
  );
}

async function getOpenApiDoc() {
  const url = `${apiBaseUrl}/openapi.json`;
  let res = await fetch(url);
  const json = await res.json();
  if (!res.ok) {
    throw new Error(json);
  }
  const out = { data: json, status: res.status };
  return out;
}
