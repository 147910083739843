import { Outlet, useLocation } from "react-router-dom";
import cx from "classcat";
import { Header } from "./Header";
import { Nav, NavItem } from "./lib/Nav";
import { IconAccessPointGroup } from "../icons/IconAccessPointGroup";
import { IconAccessPoint } from "../icons/IconAccessPoint";
import { IconService } from "../icons/IconService";
import styles from "./Layout.module.css";
import { BackLink } from "./lib/BackLink";
import { IconDevice } from "../icons/IconDevice";

export function Layout() {
  const location = useLocation();
  // Meh.
  const isCreateScreen = location.pathname.endsWith("/new");
  // Remove /new
  const backTo = location.pathname.slice(0, -4);
  return (
    <>
      <Header />
      {isCreateScreen ? (
        <div className={styles.backLinkContainer}>
          <BackLink to={backTo}>Back</BackLink>
        </div>
      ) : (
        <div className={cx([styles.navContainer, styles.limit])}>
          <Nav>
            {[
              {
                to: "/ap-groups",
                text: "AP Groups",
                icon: IconAccessPointGroup,
                isActive:
                  location.pathname.startsWith("/ap-groups") ||
                  location.pathname === "/",
              },
              {
                to: "/access-points",
                text: "Access Points",
                icon: IconAccessPoint,
              },
              { to: "/services", text: "Services", icon: IconService },
              { to: "/devices", text: "Devices", icon: IconDevice },
            ].map((x, i) => (
              <NavItem key={i} to={x.to} isActive={x.isActive}>
                {x.icon == null ? null : <x.icon />}
                {x.text}
              </NavItem>
            ))}
          </Nav>
        </div>
      )}
      <main className={cx([styles.main, styles.limit])}>
        <Outlet />
      </main>
    </>
  );
}
