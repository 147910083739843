import * as React from "react";
import SwaggerParser from "@apidevtools/swagger-parser";
import toJsonSchema from "@openapi-contrib/openapi-schema-to-json-schema";
import traverse from "json-schema-traverse";
import { capitalCase } from "capital-case";
import cloneDeep from "lodash.clonedeep";
import { titles } from "./titles";

export function useSchema(params: { name: string }) {
  const { schemas } = React.useContext(SchemasContext);
  const schema = schemas?.[params.name];
  if (schema == null) {
    throw new Error(`Schema not found: name: ${params.name}`);
  }
  return { schema };
}

export function SchemasProvider(
  props: TSchemasContext & { children: React.ReactNode }
) {
  const { children, schemas } = props;
  return (
    <SchemasContext.Provider value={{ schemas }}>
      {children}
    </SchemasContext.Provider>
  );
}

type TSchemas = { [k: string]: any };
type TSchemasContext = { schemas: TSchemas };

const SchemasContext = React.createContext<TSchemasContext>({
  // @ts-ignore
  schemas: null,
});

export async function getSchemas(doc: any): Promise<TSchemas> {
  const api = await SwaggerParser.dereference(doc);

  const schemas = (api as any).components?.schemas;
  if (schemas == null) {
    throw new Error("No schemas found");
  }

  let out: TSchemas = {};

  for (let k in schemas) {
    const _schema = schemas[k];
    out[k] = processSchema(_schema);
  }

  return out;
}

function processSchema(__schema: any) {
  const schema = toJsonSchema(cloneDeep(__schema));
  delete schema.$schema;
  delete schema.title;
  traverse(schema, {
    cb: (x) => {
      if (x.properties != null) {
        for (let p in x.properties) {
          if (!Object.prototype.hasOwnProperty.call(p, "title")) {
            x.properties[p].title = (titles as any)[p] || capitalCase(p);
          }
          if (p.endsWith("Uuid") && x.properties[p].pattern) {
            // Workaround for regex only allowing capital letters
            delete x.properties[p].pattern;
          }
        }
      }
    },
  });

  return schema;
}
