import * as React from "react";
import { MdArrowBack } from "react-icons/md";
import { PressableLink } from "./PressableLink";
import styles from "./BackLink.module.css";

export function BackLink(props: {
  to: React.ComponentProps<typeof PressableLink>["to"];
  children: string;
}) {
  const { children, ...rest } = props;
  return (
    <PressableLink {...rest}>
      <div className={styles.layout}>
        <MdArrowBack />
        {children}
      </div>
    </PressableLink>
  );
}
