import * as React from "react";

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider(props: T & { children: React.ReactNode }) {
  const { children, ...rest } = props;
  return <AuthContext.Provider value={rest}>{children}</AuthContext.Provider>;
}

type T = {
  token: string;
  signIn: (token: string, cb?: () => void) => void;
  signOut: () => void;
};

const AuthContext = React.createContext<T>(
  // @ts-ignore
  undefined
);
