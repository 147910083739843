export const titles = {
  id: "ID",
  uuid: "UUID",
  ssid: "SSID",
  subscriberId: "Subscriber ID",
  planId: "Plan ID",
  micronetId: "Micronet ID",
  vlan: "VLAN",
  ssidList: "SSID",
  numDevicesAllowed: "Number of Devices Allowed",
  ipAddress: "IP Address",
  macAddress: "MAC Address",
  psks: "PSKs",
  extenderSsid: "Extender SSID",
  vxlanEndpoint: "VXLAN Endpoint",
};
