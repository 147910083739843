import { MenuPopover } from "./lib/MenuButton";
import styles from "./lib/MenuPopover.module.css";

export function HeaderMenuPopover(props: { children: any }) {
  return (
    <MenuPopover>
      <div className={styles.popoverInner}>{props.children}</div>
    </MenuPopover>
  );
}
