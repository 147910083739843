import * as React from "react";

export function useUser() {
  return React.useContext(UserContext);
}

export function UserProvider(props: T & { children: React.ReactNode }) {
  const { children, user } = props;
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
}

type User = { uuid: string };
type T = { user: User };

const UserContext = React.createContext<T>({
  // @ts-ignore
  user: null,
});
